.App {
  background-color: #282c34;
  /* text-align: center; */
  min-height: 100vh;
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  font-size: calc(5px + 2vmin);
  margin: 0;
  color: #e0e0e0;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  /* background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(20px + 2vmin);
  color: white; */
}
.header {
  background-color: #333;
  color: white;
  padding: 10px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header .logo {
  width: 80px; /* Set the width as needed */
  height: auto; /* Maintains the aspect ratio */
  margin-right: 20px; /* Adds some spacing between the logo and the title */
}

.job-container {
  margin: 20px;
  padding: 20px;
}

.jobs-grid {
  display: grid;
  grid-template-columns: 1fr 1fr; /* two columns */
  gap: 20px; /* spacing between grid items */
  margin-top: 20px;
}

.job-entry {
  background-color: #f4f4f4; /* light grey background */
  border: 1px solid #ccc; /* grey border */
  padding: 10px;
  border-radius: 8px; /* rounded corners */
}

.job-title {
  color: #333; /* dark grey text for title */
  font-size: 18px; /* larger font size for title */
  text-decoration: none; /* remove underline from links */
  margin-bottom: 5px; /* space below the title */
  display: block; /* makes the link fill the container width */
}

.job-description {
  color: #666; /* medium grey text for description */
  font-size: 14px; /* standard font size for description */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* limits to three lines */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis; /* ellipsis for text overflow */
}

.job-detail-container {
  max-width: 800px; /* limits the width of the container */
  margin: 20px auto; /* centers the container on the page */
  padding: 20px;
  background-color: #ffffff; /* white background */
  border: 1px solid #e0e0e0; /* light grey border */
  color: #282c34;
  border-radius: 10px; /* rounded corners */
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* subtle shadow */
}

.job-title_ {
  font-size: 28px; /* large font size for title */
  color: #2a2a2a; /* dark grey color for text */
  margin-bottom: 10px; /* space below the title */
}

.job-description_ {
  font-size: 16px; /* comfortable reading font size */
  color: #4a4a4a; /* slightly lighter grey color for body text */
  line-height: 1.6; /* enhances readability */
  text-align: justify; /* justifies the text */
}

.form-container {
  max-width: 500px;
  margin: 50px auto;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  color: #282c34;
}

.input-field {
  width: calc(100% - 20px); /* 100%; */
  padding: 10px 15px;
  margin-bottom: 10px;
  border: 2px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box; /* Includes padding and border in the element's total width and height */
  transition: border-color 0.3s;
}
.file-input {
  border: 2px dashed #ddd;
  background-color: #fafafa;
  padding: 10px;
  text-align: center;
  margin-bottom: 10px;
}
.submit-button {
  width: calc(100% - 20px); /* 100%; */
  padding: 10px 15px;
  margin-bottom: 10px;
  border: 2px solid #ddd;
  color: #ffffff;
  cursor: pointer;
  border-radius: 5px;
  box-sizing: border-box; /* Includes padding and border in the element's total width and height */
  transition: border-color 0.3s;
  background-color: #007bff;
}